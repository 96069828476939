<template>
	<div>
		<div
			class="home-grid"
			:class="`device_is_${deviceType} index`">
			<headLeft class="col-span-2 h" />
			<headRight />
			<!-- <Ad
				ref="adpc1"
				v-if="isPC"
				:ads="adsensConfig.ad1"
				class="adpc1" />
			<Ad
				ref="adm1"
				v-if="!isPC"
				:ads="adsensConfig.ad1"
				class="adm1" />
			<Ad
				ref="adpc2"
				v-if="isPC"
				:ads="adsensConfig.ad2"
				class="adpc2" />
			<Ad
				v-if="!isPC"
				:ads="adsensConfig.ad2"
				ref="adm2"
				class="adm2" /> -->
			<a
				:data-gamename="item.gameName"
				:key="item.gameName + index"
				:class="[0, 7, 12, 22, 27].includes(index) ? 'big' : ''"
				v-for="(item, index) in curGames"
				class="item as gameIcon gameDom"
				:href="'/click-games/' + item.gameName?.toLowerCase().replaceAll(' ', '-')">
				<img
					loading="lazy"
					decoding="async"
					class="icon"
					:src="item.logoUrl"
					alt="" />
				<div
					class="gameName"
					v-show="deviceType !== 'mobile'">
					{{ item.gameName }}
				</div>
				<div class="co"></div>
			</a>
		</div>
		<load-btn
			:pageSize="112"
			@load="load"
			:ifAllLoad="ifAllLoad"
			@pageChange="onPageChange"
			:currentPage="currentPage"
			:total="total" />
		<bottomCate />
		<!-- <Ad
			v-if="isPC"
			:ads="adsensConfig.ad3"
			class="adpc3"
			ref="adpc3" />
		<Ad
			v-if="!isPC"
			:ads="adsensConfig.ad3"
			class="adm3"
			ref="adm3" /> -->
		<div class="intro">
			<div class="nav">
				<a href="/">GAMES</a>
				<span>›</span>
				<a :href="'/' + gameCate.toLocaleLowerCase().replaceAll(' ', '-')">{{ gameCate }}</a>
			</div>
			<h1>{{ gameCate }}</h1>

			<h2>{{ curDescArr[0][0] }}</h2>
			<p>{{ curDescArr[0][1] }}</p>

			<h2>{{ curDescArr[1][0] }}</h2>
			<p>{{ curDescArr[1][1] }}</p>

			<h2>{{ curDescArr[2][0] }}</h2>
			<p>{{ curDescArr[2][1] }}</p>
		</div>
	</div>
</template>

<script>
import { getList } from '../api'
import LoadBtn from '../components/loadBtn.vue'
import { mapState } from 'vuex'
export default {
	components: { LoadBtn },
	data() {
		return {
			curGames: [],
			currentPage: 1,
			total: 100,
			allGames: [],
			gameDesc: {
				'New Games': {
					'Fresh Releases': 'Be the first to experience the latest and greatest games with our New Games category. We bring you the freshest releases, hot off the press, ensuring you’re always up to date with the newest titles.',
					'Innovative Gameplay': 'Explore innovative gameplay mechanics, cutting-edge graphics, and unique storylines that push the boundaries of what’s possible in gaming. Our New Games selection offers a wide variety of genres to cater to all tastes and preferences.',
					'Stay Updated': 'Stay updated with the latest trends and developments in the gaming world. With new games added regularly, you’ll never run out of fresh content to enjoy. Dive into the newest adventures and be among the first to discover tomorrow’s classics.',
				},
				'Hot Games': {
					'Trending Titles': 'Discover the hottest games that everyone is talking about on poking.games. Our Hot Games category features the most popular and trending titles that are capturing the attention of gamers worldwide.',
					'Must-Play Experiences': 'These games are must-plays, offering exceptional gameplay, stunning graphics, and engaging storylines that keep players hooked. Whether you’re into action, puzzle, adventure, or any other genre, our Hot Games selection has something for everyone.',
					'Join the Craze': 'Stay ahead of the curve and join the craze by playing the games that are currently making waves in the gaming community. With new hits constantly being added, you’ll always find something exciting and fresh to play.',
				},
				'Number Games': {
					'Sharpen Your Skills': 'Sharpen your math skills and enjoy brain-teasing fun with our number games. These games challenge you to solve numerical puzzles, perform calculations, and think logically.',
					'For All Ages': 'From Sudoku to math-based puzzles, our number games cater to players of all ages and skill levels. Whether you’re looking to improve your arithmetic or enjoy a relaxing mental workout, our games provide a stimulating experience.',
					'Joy of Solving': 'Dive into the world of numbers and discover the joy of solving mathematical challenges. With a variety of games to choose from, you’ll always find something to keep your mind engaged.',
				},
				'Strategy Games': {
					'Tactical Challenges': 'Put your tactical skills to the test with our engaging strategy games. Plan your moves carefully, manage resources, and outsmart your opponents to achieve victory.',
					'Diverse Mechanics': 'Our strategy games span a wide range of genres, from turn-based tactics to real-time strategy. Whether you’re building an empire, commanding an army, or solving complex puzzles, each game requires thoughtful planning and execution.',
					'Intellectual Entertainment': 'Enjoy the challenge of strategic gameplay and the satisfaction of seeing your plans come to fruition. With diverse mechanics and deep gameplay, our strategy games offer endless hours of intellectual entertainment.',
				},
				'Shooting Games': {
					'Test Your Marksmanship': 'Take aim and test your marksmanship with our thrilling shooting games. Engage in intense firefights, hunt down targets, and master a variety of weapons in action-packed scenarios.',
					'Realistic and Fast-Paced': 'Our shooting games offer realistic graphics and fast-paced gameplay that challenge your reflexes and strategic thinking. Whether you prefer first-person shooters or tactical combat games, we have something to suit your style.',
					'Prove Your Skills': 'Join the action and prove your skills as you take on enemies, complete missions, and strive for the highest scores.',
				},
				'Adventure Games': {
					'Epic Journeys': 'Embark on epic journeys and discover new worlds with our adventure games. These games offer rich narratives, immersive environments, and compelling characters that draw you into their stories.',
					'Exploration and Discovery': 'Explore mysterious lands, solve intricate puzzles, and overcome daunting obstacles as you progress through each adventure. With a focus on exploration and discovery, our adventure games provide a captivating experience that keeps you engaged from start to finish.',
					'Something for Every Explorer': 'Whether you’re seeking a grand quest or a smaller, more personal story, our collection of adventure games has something for every explorer at heart.',
				},
				'Match 3 Games': {
					'Colorful and Strategic': 'Step into a world of colorful tiles and strategic matching with our match 3 games. Align three or more tiles to clear them from the board and achieve high scores.',
					'Addictive Gameplay': 'Our match 3 games are perfect for players of all ages, offering simple yet addictive gameplay that keeps you coming back for more. With a variety of themes and levels, each game presents new challenges and exciting twists.',
					'Delightful Experience': 'Enjoy the satisfaction of making matches and unlocking power-ups as you progress through each game. Whether you’re looking for a quick distraction or a long-term challenge, our match 3 games are sure to delight.',
				},
				'Sport Games': {
					'Get in the Game': 'Get in the game with our diverse collection of sports games. Whether you’re a fan of football, basketball, tennis, or any other sport, we have games that let you experience the thrill of the competition from the comfort of your home.',
					'Realistic and Intuitive': 'Our sports games feature realistic graphics and intuitive controls, making it easy to jump right in and start playing. Compete in tournaments, challenge your friends, or practice your skills in solo modes.',
					'Enjoy the Action': 'No matter your favorite sport, our games offer a fun and engaging way to enjoy the action and excitement of athletic competition.',
				},
				'Racing Games': {
					'Feel the Thrill': 'Feel the thrill of the race with our exhilarating racing games. Speed through a variety of tracks and terrains, from city streets to off-road trails, and compete to be the fastest driver on the course.',
					'Immersive Experience': 'With realistic graphics and dynamic gameplay, our racing games offer an immersive experience that captures the excitement of high-speed competition. Customize your vehicles, master tricky maneuvers, and race against the clock or other players.',
					'Endless Fun': 'Whether you’re a casual racer or a hardcore speed enthusiast, our racing games provide endless hours of adrenaline-pumping fun.',
				},
				'Puzzle Games': {
					'Challenge Your Mind': 'Unleash your potential and enhance your problem-solving skills with our expansive collection of puzzle games. Designed to push your logic, creativity, and strategic thinking to new heights, each game offers a unique and engaging challenge.',
					'A Puzzle for Every Mind': "Whether you're a fan of classic jigsaw puzzles, intricate brain teasers, or complex Sudoku, our selection caters to all skill levels. Whether you’re just starting out or a seasoned puzzle master, you’ll find plenty of games to challenge and entertain you.",
					'The Joy of Solving': 'Step into a world of intriguing puzzles and experience the deep satisfaction of solving complex problems, piece by piece. Ready to test your skills? Dive in and start your puzzle adventure today.',
				},
				'Casual Games': {
					'Relax and Unwind': 'Our casual games are perfect for those looking to unwind and have fun without the pressure of complex rules or intense competition. These games are designed to be easy to pick up and play, making them ideal for short breaks or leisurely afternoons.',
					'Light-Hearted Fun': 'Enjoy a variety of light-hearted and engaging games that cater to all ages and preferences. From relaxing puzzles to charming adventures, our casual games provide a delightful escape from the everyday hustle and bustle.',
					'Pleasant and Enjoyable': 'Whether you’re looking to pass the time or simply relax, our collection of casual games offers a pleasant and enjoyable experience for everyone.',
				},
				'Action Games': {
					'Heart-Pounding Excitement': 'Action games on poking.games offer heart-pounding excitement and fast-paced gameplay that keeps you on the edge of your seat. Whether you’re battling fierce enemies, navigating through treacherous terrains, or mastering complex moves, our action games deliver non-stop thrills.',
					'Epic Adventures': 'Dive into epic adventures where every second counts, and your reflexes are tested to the max. With stunning graphics and immersive soundtracks, our action games provide an unparalleled gaming experience.',
					'Unleash Your Inner Hero': "From classic beat 'em ups to modern action-packed titles, there's something for every adrenaline junkie. Get ready to unleash your inner hero and conquer the challenges that await you.",
				},
			},
			ifAllLoad: false,
		}
	},
	computed: {
		gameCateType() {
			if (this.gameCate.indexOf('Hot') >= 0) return 'Hot'
			if (this.gameCate.indexOf('New') >= 0) return 'New'
			return this.gameCate
		},
		...mapState(['deviceType']),
		curDescArr() {
			// console.log(this.$route.path)
			return Object.entries(this.gameDesc[this.gameCate])
		},
		gameCate() {
			return this.$route.path
				.substring(1)
				.split('-')
				.map((e) => {
					let s = e

					return s[0].toUpperCase() + s.substring(1)
				})
				.join(' ')
		},
	},
	async mounted() {
		if (this.deviceType === 'mobile') {
			await this.getList({
				pageSize: 12,
				pageNum: this.currentPage,
				type: this.gameCateType,
			})

			this.addCurGame()
		} else {
			await this.getList({
				pageSize: 112,
				pageNum: this.currentPage,
				type: this.gameCateType,
			})
		}
		setTimeout(() => {
			this.startOb()
		})
		// await this.loadAdSenseScript() // 重新加载广告脚本
	},
	methods: {
		//广告相关start
		async loadAdSenseScript() {
			// 其他需要在初次加载时执行的操作
			// 先检查广告是否已经加载
			if (window.adsbygoogle && window.adsbygoogle.loaded) {
				// 检查广告是否已经加载
				console.log('Adsense script already loaded.')
				this.$eventrack('adscript_loaded', 'expose')
				this.loadAdWithDelay()
				return // 如果已加载，直接返回
			}
			await this.loadScriptConditionally()
		},
		loadScriptConditionally() {
			console.log(this.$store.state.adsensConfig)
			// 判断广告脚本URL是否存在
			if (!this.$store.state.adsensConfig?.scriptUrl) {
				console.log('广告脚本的URL不存在,终止加载广告外链')
				this.$eventrack('no_adscript_config', 'expose')
				return
			}
			// 检查该脚本是否已经被添加
			const existingScript = document.querySelector(`script[src="${this.$store.state.adsensConfig.scriptUrl}"]`)
			if (existingScript) {
				this.$eventrack('adscript_exist', 'expose')
				console.log('脚本已存在，无需重新添加')
				return
			}

			console.log('准备插入脚本')
			const script = document.createElement('script')
			script.src = this.$store.state.adsensConfig?.scriptUrl
			script.crossOrigin = 'anonymous'
			script.async = true

			const header = document.getElementsByTagName('head')[0]
			header.appendChild(script)

			this.$eventrack('adscript_add_success', 'expose')
			script.onload = this.loadAdWithDelay.bind(this) // 使用 bind 确保 this 指向正确
			console.log('脚本插入完成,加载完成,执行加载插入广告及监听操作')
		},
		loadAdWithDelay() {
			setTimeout(() => {
				this.displayAd()
			}, 500)
		},
		async displayAd() {
			await this.$nextTick()

			// 获取所有 ads 元素的 refs，过滤掉非广告的 ref
			const adsElements = Object.entries(this.$refs)
				.filter(([key]) => key.startsWith(this.isPC ? 'adpc' : 'adm')) // 只选择以 'ad' 开头的 refs
				.flatMap(([, ref]) => ref) // 展开并获取所有元素

			if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
				console.log('Adsense script not loaded yet, delaying ad display.')
				setTimeout(this.displayAd, 500) // 延迟再次尝试
				return
			}

			console.log(adsElements) // 检查是否包含 <ins> 标签
			adsElements.forEach((ad) => {
				console.log(ad) // 输出每个广告元素
				console.log('ready to push')
				;(window.adsbygoogle = window.adsbygoogle || []).push({})
			})
		},
		startOb() {
			const o = new IntersectionObserver(
				(entries, observer) => {
					entries.forEach((item) => {
						// isIntersecting 表示是否出现
						if (item.isIntersecting) {
							this.$logEvent('game_icon_expose:' + item.target.dataset.gamename, 'expose')
							observer.unobserve(item.target)
						}
					})
				},
				{
					root: null,
					rootMargin: '0px',
					threshold: 0.1,
				},
			)
			this.ob = o
			let games = document.querySelectorAll('.gameDom')
			for (const el of games) {
				o.observe(el)
			}
		},
		async load() {
			// 全部加载完就不请求

			await this.getList({
				pageSize: 12,
				pageNum: ++this.currentPage,
				type: this.gameCateType,
			})
			this.addCurGame()
			setTimeout(() => {
				this.startOb()
			})
		},
		// 移动端点击加载时，把获取到的allGame游戏添加进当前curGame数组
		addCurGame() {
			// 初次添加12个
			if (this.curGames.length === 0) {
				this.curGames.push(...this.allGames.splice(0, 12))
			} else {
				// 每次加载添加10个进当前
				this.curGames.push(...this.allGames.splice(0, 10))
				if (this.allGames.length === 0) {
					this.ifAllLoad = true
					return
				}
			}
		},
		// mobile:获取新分页数据添加进allGames;pc新分页替换curGames
		async getList({ type = '', pageSize = 24, pageNum = 1 }) {
			this.$store.state.collocationLoading = true
			// console.log(type, 'pageSize', pageSize, 'pageNum', pageNum)
			let arr = []
			const { infoList, total } = await getList(arguments[0])
			arr = infoList
			this.total = total
			if (this.deviceType === 'mobile') {
				this.allGames.push(...arr)
			} else {
				this.curGames.splice(0, this.curGames.length, ...arr)
				// console.log(this.curGames)
			}
			this.$store.state.collocationLoading = false
			this.$logEvent('page_' + this.gameCateType, 'expose')
		},
		async onPageChange(curIndex) {
			this.currentPage = curIndex
			await this.getList({ pageNum: this.currentPage, pageSize: 112 })
			setTimeout(() => {
				window.scrollTo(0, 0)
			})
			this.startOb()
		},
	},
}
</script>
<style lang="scss" scoped>
.home-grid {
	.item {
		overflow: hidden;
		position: relative;
		border-radius: rem(20);
		box-shadow: rem(0) rem(5) rem(15) rem(0) rgba(0, 0, 0, 0.4);
		// background-color: #fff;
		&.big {
			grid-column: span 2 / span 2;
			grid-row: span 2 / span 2;
		}
	}
	.icon {
		height: 100%;
		width: 100%;
		vertical-align: middle;
		position: absolute;
		inset: 0px;
		object-fit: cover;
	}
}
.intro {
	background-color: #fff;
	padding: rem(30);
	margin-top: rem(50);
	border-radius: rem(20);
	a {
		color: #002b51;
	}
	.nav {
		color: #002b51 !important;
		font-family: Roboto;
		font-size: rem(20);
		font-style: normal;
		font-weight: 400;
		line-height: rem(40); /* 200% */
		span {
			margin: 0 rem(10);
		}
	}
	h1 {
		color: #002b51 !important;
		font-size: rem(40);
		font-style: normal;
		font-weight: 900;
		line-height: rem(60); /* 150% */
		letter-spacing: rem(1);
	}
	h2 {
		color: #002b51 !important;
		font-size: rem(30);
		font-style: normal;
		font-weight: 900;
		line-height: rem(60); /* 150% */
		letter-spacing: rem(1);
	}
	.label1 {
		margin-top: rem(-20);
		.itemm {
			margin-right: rem(20);
			border-radius: rem(230);
			padding: 0 rem(25);
			display: inline-block;
			box-shadow: none;
			background-color: #e5e7eb;
			color: #5d6b84;
			font-family: Roboto;
			font-size: rem(25);
			font-style: normal;
			font-weight: 600;
			line-height: rem(40); /* 200% */
			svg {
				vertical-align: sub;
				width: 0.4rem;
				// margin-right: 0.1rem;
			}
		}
	}
	p {
		color: #002b51 !important;
		font-family: Roboto;
		font-size: rem(24);
		font-style: normal;
		font-weight: 400;
		line-height: rem(40); /* 166.667% */
		letter-spacing: rem(1);
	}
}
.gameIcon:hover {
	transform: translateY(-4px);
	.gameName {
		display: block;
	}
}
.gameIcon .co {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: linear-gradient(transparent 50%, rgba(0, 0, 0, 0.5));
	opacity: 0;
}
.gameIcon:hover .co {
	animation: coAni 0.2s ease-out forwards;
}
.gameIcon:hover .gameName {
	animation: nameAni 0.3s ease-out forwards;
}
@keyframes coAni {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes nameAni {
	from {
		opacity: 0;
		bottom: 0;
	}
	to {
		opacity: 1;
		bottom: 10px;
	}
}
.gameName {
	display: none;
	text-align: center;
	color: #fff;
	font-family: Roboto;
	font-size: 13px;
	position: absolute;
	z-index: 10;
	left: 50%;
	transform: translateX(-50%);
}
.big .gameName {
	font-size: 20px !important;
}
</style>
